<template data-app>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title ref="leads_title" class="leads-title">
          Лиды (NEW)
          <div class="ml-auto">
            <span v-if="activeManagers.length"
                style="font-size: 14px;" class="mr-2">На линии: <span @click="setIsActiveManager(managerId, false)"
                                                                        class="mr-2" v-for="managerId in activeManagers"
                                                                        :key="managerId">{{
                $root.managerName(managerId)
              }}<IconTip
                  iconStyle="cursor: pointer" icon="close" title="Убрать с линии"/></span></span>
            <v-btn v-if="+$store.state.user.leads_distribution_enabled===2 && +filters.status === 0"
                   @click="setIsActiveManager($store.state.user.n_id, false)"
                   :loading="set_is_active_manager_loading"
                   outlined style="text-transform: unset">Нет на месте
            </v-btn>
            <a style="font-size: 14px;text-decoration: underline !important;" v-if="$store.state.user.rights.includes('new-clients-funnel')" href="#/new-clients-funnel">Воронка по новым клиентам</a>
          </div>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row class="align-center" style="gap: 8px">
            <v-chip @click="filters.status = index;filters.page = 0;getLeads(false);filters.source = ''"
                    v-for="(s, index) in statuses" :key="index" :color="+filters.status===index ? '#9BF0F5' : '#fff'"
                    class="mr-2" label>
              {{ s.title }}
              <CBadge style="margin-left: 5px;color: white" v-if="$store.state.updatingInfo['lead_counts'+index]"
                      :color="['danger', 'warning'][index]">
                {{ $store.state.updatingInfo['lead_counts' + index] }}
              </CBadge>
              <CBadge v-if="$store.state.updatingInfo['lead_counts_checked'] && !index"
                      style="margin-left: 5px;color: white;background: #8a93a2">
                {{ $store.state.updatingInfo['lead_counts_checked'] }}
              </CBadge>
            </v-chip>
            <v-text-field style="width: 250px;display: inline-block; flex: initial" outlined
                          placeholder="Поиск по телефону клиента"
                          @change="getLeads(false)" v-model="filters.phone"/>
<!--            <v-col  cols="4">-->
              <v-select v-if="+filters.status === 2"
                  style="max-width: 220px; width: 100%" outlined
                        :items="allManagers" item-value="n_id" item-text="login" @change="onManagerSelect"
                        clearable placeholder="Фильтр по менеджеру"/>
<!--            </v-col>-->
            <span>
              <v-checkbox v-if="+$store.state.user.okk"
                  v-model="filters.okk"
                          class="ml-2"
                          @change="getLeads(false)"
                          label="ОКК" true-value="1" false-value="0"/>
            </span>
          </v-row>
          <v-card-text
              v-if="+$store.state.user.leads_distribution_enabled && +$store.state.user.leads_distribution_enabled!==2 && +filters.status === 0"
              style="height: 300px"
              class="d-flex justify-center align-center flex-column">
            Для работы с лидами нажмите кнопку
            <v-btn @click="setIsActiveManager($store.state.user.n_id, true)" class="mt-4"
                   :loading="set_is_active_manager_loading">Я на месте
            </v-btn>
          </v-card-text>
          <template v-else>
            <DatePeriod v-if="+filters.status===2" @change="getLeads(false)" v-model="filters.date"></DatePeriod>
            <div class="lead-stats" v-for="(statsGroup, key) in
          Object.fromEntries(Object.entries(stats).filter(([k, a])=>['bySource', 'byRejectReason', 'byType'].includes(k))
          .sort(([key1, arr],[key2, arr2])=>statsOrder.indexOf(key1) - statsOrder.indexOf(key2))
          .filter(([_key, arr])=>+filters.status === 0 ? _key === 'bySource' : _key))"
                 :key="key">
              <template>
                <v-row>
                  <v-col cols="2" class="pa-0 pr-2 pt-2" style="color: #333333">
                    {{ statsHeaders[key].title }}, {{ statsHeaders[key].count }}шт&nbsp;
                    <b v-if="key !== 'byType' && stats.bySource.reduce((acc, item)=>acc+ +item.count, 0)">
                      {{
                        key !== 'bySource' ? parseFloat((statsHeaders[key].count / stats.bySource.reduce((acc, item) => acc + +item.count, 0)) * 100).toFixed(1) + '%' : ''
                      }}
                    </b>
                  </v-col>
                  <v-col class="pa-0" cols="10">
                    <v-tooltip top v-for="(stat, i) in statsGroup" :key="i" :disabled="true">
                      <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-chip @click="onSourceSelect(stat)"
                          class="mr-2" label :color="filters.source ===stat.source
                          || filters.reject_reason === stat.reject_reason
                           || filters.type === stat.type ? '#9BF0F5' : '#fff'">
                    <img v-if="sources[stat.source]" class="mr-2" width="20" :src="sources[stat.source].icon"
                         :alt="stat.source"/>
                    <span v-else>{{ stat.source || stat.reject_reason || stat.title }}&nbsp;</span>
                    <span v-if="key === 'bySource'">
                      <span style="color: green">
                      {{
                          stats.bySourceSuccessful.find(_stat => _stat.source === stat.source) ? +stats.bySourceSuccessful.find(_stat => _stat.source === stat.source).count : 0
                        }}
                      </span>
                      /{{ +stat.count || 0 }}шт
                    </span>
                    <span v-else-if="key === 'byType'">
                      <span style="color: green">
                        {{
                          stats.byTypeSuccessful.find(_stat => _stat.type === stat.type) ? +stats.byTypeSuccessful.find(_stat => _stat.type === stat.type).count : 0
                        }}
                      </span>
                      /{{ +stat.count || 0 }}шт
                    </span>
                    <span v-else>{{ +stat.count || 0 }}шт</span>
                    <span v-if="key==='bySource'" style="font-size: 12px;font-weight: bold" class="ml-2">
                      {{
                        parseFloat(((stats.bySourceSuccessful.find(_stat => _stat.source === stat.source) ?
                            +stats.bySourceSuccessful.find(_stat => _stat.source === stat.source).count : 0) / (+stat.count ? +stat.count : 1)) * 100).toFixed(1)
                      }}%
                    </span>
                    <span v-else-if="key === 'byType'" class="ml-2" style="font-weight: 500">
                      {{
                        parseFloat(((stats.byTypeSuccessful.find(_stat => _stat.type === stat.type) ?
                            +stats.byTypeSuccessful.find(_stat => _stat.type === stat.type).count : 0) / (+stat.count ? +stat.count : 1)) * 100).toFixed(1)
                      }}%
                    </span>
                    <span v-else style="font-size: 12px;font-weight: bold" class="ml-2">
                      {{
                        parseFloat(((stats.byRejectReason.find(_stat => _stat.reject_reason === stat.reject_reason) ?
                            +stats.byRejectReason.find(_stat => _stat.reject_reason === stat.reject_reason).count : 0) / (stats.byRejectReason.reduce((acc, item) => acc + +item.count, 0) || 1)) * 100).toFixed(1)
                      }}%
                    </span>
                  </v-chip>
                </span>
                      </template>
                      <!--              <span>{{ stat.hint || key }}</span>-->
                    </v-tooltip>
                  </v-col>
                </v-row>
              </template>
            </div>
            <v-data-table
                ref="leads_table"
                hover
                :items="filteredRows"
                :headers="headers[+filters.status]"
                item-key="id"
                class="leads_table mt-4"
                :loading="loading"
                :footer-props="{'items-per-page-options':[+filters.limit],
              showFirstLastPage: true,
              showCurrentPage: true,
              }"
                no-data-text="Лидов нет"
                loading-text="Загрузка..."
                expand-icon="mdi-chevron-up"
                mobile-breakpoint="0"
                :page="+filters.page+1"
                :items-per-page="+filters.limit"
                :server-items-length="selectedCount ? selectedCount : stats.bySource.reduce((acc, item)=>acc+ +item.count, 0)"
                @update:page="onUpdatePage"
            >
              <template v-slot:item="{item}">
                <tr @click="openLead(item)" :style="+item.checked ? 'opacity: 0.6' : ''">
                  <td>
                    <LeadSourceIcon :item="item"/>
                  </td>
                  <td style="min-width: 230px">
                    <v-row>
                      <v-col cols="1" v-if="!item.isClientLead"
                             class="pa-0 mr-1">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" size="16" class="pb-1">mdi-broom</v-icon>
                          </template>
                          Лид по клинеру
                        </v-tooltip>
                      </v-col>
                      <v-col class="pa-0">
                        <v-tooltip top :disabled="!item.type.description" max-width="240px">
                          <template v-slot:activator="{ on, attrs }">
                            <span style="max-width: 150px; white-space: normal;display: block" v-bind="attrs" v-on="on">{{
                                item.type.title
                              }}</span>
                          </template>
                          {{ item.type.description }}
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <CallLabel class="ml-1" @click.stop="" v-if="item.call" :call="item.call"/>
                    <div v-if="+item.checked && !(+item.status)">Ответил:
                      {{ $root.managerName(item.manager_checked) }}
                    </div>
                  </td>
                  <!--                <td>-->
                  <!--                  <InfoTip v-if="item.type && item.type.title"-->
                  <!--                           :text="item.type.title" :value="item.type.description"/>-->
                  <!--                </td>-->
                  <td>
                    <div>{{
                        $moment(item.datetime_lastObj ? item.datetime_lastObj.str : item.datetime_createObj.str).format('D MMM HH:mm')
                      }}
                    </div>
                    <div v-if="item.datetime_lastObj" style="color: #9b9b9b">С
                      {{ $moment(item.datetime_createObj.str).format('D MMM HH:mm') }}
                    </div>
                  </td>
                  <td v-if="+filters.status===1">
                    <div v-if="item.datetime_waitObj">{{
                        $moment(item.datetime_waitObj.str).format('D MMM HH:mm')
                      }}
                    </div>
                  </td>
                  <td v-if="+filters.status===2">
                    <div v-if="item.datetime_closedObj">{{
                        $moment(item.datetime_closedObj.str).format('D MMM HH:mm')
                      }}
                    </div>
                  </td>
                  <td v-if="+filters.status===2" style="width: 240px; white-space: normal">
                    <b v-if="+filters.status === 2 && $root.managerName(item.manager_closed)">
                      {{ $root.managerName(item.manager_closed) }}
                    </b>
                    <div v-if="item.reject_reason">{{ item.reject_reason }}</div>
                    <div v-else>Заказ</div>
                  </td>
                  <td>
                    <CleanerLink v-if="item.cleaner && item.cleaner.n_id" :value="item.cleaner"/>
                    <LeadContact v-else :item="item"/>
                  </td>
                  <td>
                    <b v-if="+filters.status === 1 && $root.managerName(item.manager_wait)">
                      {{ $root.managerName(item.manager_wait) }}:
                    </b>
                    <div style="width: 200px;white-space: normal">{{ item.comment }}</div>
                  </td>
                  <td v-if="+filters.status === 2" style="min-width: 220px">
                    <OrderLink v-if="item.order && item.order.n_id" :value="item.order"/>
                  </td>
                  <td>
                    <div style="width:300px;white-space: normal;">{{ item.message }}</div>
                    <div v-if="+item.leads_num>0" style="color: #a2a2a2">+{{ item.leads_num }}
                      {{ $root.numberWord(+item.leads_num, ['сообщение', 'сообщения', 'сообщений']) }}
                    </div>
                  </td>
                  <td v-if="+filters.status === 0">
                    <v-select v-if="activeManagers.length && item.isClientLead"
                        v-model="item.assignedManager"
                              @click.prevent.stop
                              @change="updateLead(item)"
                              outlined
                              placeholder="Линия"
                              :items="activeManagers.map(id=>({id, login: $root.managerName(id)}))"
                              item-value="id"
                              item-text="login"
                    />
                  </td>
                </tr>
              </template>
            </v-data-table>
          </template>

        </v-card-text>
      </v-card>
      <LeadModal ref="lead_modal" @on-lead-update="getLeads(false)"/>
    </v-container>
  </v-app>
</template>

<script>

import DatePeriod from "../../components/DatePeriod";
import ClientLink from "../../components/ClientLink";
import OrderLink from "../../components/OrderLink";
import LeadRow from "../../components/LeadRow";
import LeadModal from "@/views/leads/LeadModal";
import InfoTip from "@/components/InfoTip";
import LeadContact from "../../components/LeadContact";
import LeadSourceIcon from "../../components/LeadSourceIcon";
import CallLabel from "../../components/CallLabel.vue";
import CleanerLink from "@/components/CleanerLink";
import IconTip from "../../components/IconTip.vue";

export default {
  name: "Leads",
  components: {
    IconTip,
    CleanerLink,
    CallLabel,
    LeadSourceIcon,
    LeadContact,
    InfoTip,
    LeadModal,
    LeadRow,
    OrderLink,
    ClientLink,
    DatePeriod
  },
  data() {
    let today = this.$moment().format('YYYY-MM-DD');
    return {
      headers: [
        [
          {text: '', value: 'index'},
          {text: 'Тип', value: 'type'},
          {text: 'Дата', value: 'datetimeObj.str'},
          {text: 'Клиент/Клинер', value: 'client.phone'},
          {text: 'Коммент', value: 'order.datetimeclean'},
          {text: 'Сообщение', value: 'history'},
          {text: 'Линия', value: 'line'},
        ],
        [
          {text: '', value: 'index'},
          {text: 'Тип', value: 'type'},
          {text: 'Дата', value: 'datetimeObj.str'},
          {text: 'След контакт', value: 'datetime_waitObj.str'},
          {text: 'Клиент', value: 'client.phone'},
          {text: 'Коммент', value: 'comment'},
          // {text: 'Заказ', value: 'order.datetimeclean'},
          {text: 'Сообщение', value: 'history'},
        ],
        [
          {text: '', value: 'index'},
          {text: 'Тип', value: 'type'},
          {text: 'Дата', value: 'datetimeObj.str'},
          {text: 'Дата закрытия', value: 'datetime_closeObj.str'},
          {text: 'Результат', value: 'result'},
          {text: 'Клиент', value: 'client.phone'},
          {text: 'Коммент', value: 'comment'},
          {text: 'Заказ', value: 'order.datetimeclean'},
          {text: 'Сообщение', value: 'history'},
        ]
      ],
      loading: false,
      statuses: [
        {title: 'Входящие'},
        {title: 'Ожидание'},
        {title: 'Обработанные'},
      ],
      sources: {},
      rows: [],
      filteredRows: [],
      activeManagers: [],
      request: 0,
      filters: {
        page: 0,
        limit: 20,
        date: [today, today],
        source: '',
        phone: '',
        status: 0,
        reject_reason: '',
        type: '',
        okk: 0
      },
      stats: {bySource: [], bySourceSuccessful: [], byRejectReason: []},
      selectedCount: 0,
      statsOrder: ['bySource', 'byType', 'byRejectReason'],
      statsTitles: ['Всего', 'По типу', 'Не успешных'],
      set_is_active_manager_loading: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    allManagers() {
      return this.$store.state.user.managers;
    },
    statsHeaders() {
      let obj = this.statsOrder.reduce((obj, key, i) => {
        obj[key] = {
          title: this.statsTitles[i],
          count: this.stats[key] ? this.stats[key].reduce((acc, val) => acc + +val.count, 0) : 0,
          percent: 0,
        };
        return obj;
      }, {});
      return obj;
    }
  },
  mounted() {
    let filterParams = Object.keys(this.filters);
    if (Object.keys(this.$route.query).filter(query => filterParams.includes(query)).length) {
      Object.assign(this.filters, this.$route.query);
    }
    this.getLeads();
  },
  beforeDestroy() {
  },
  methods: {
    getLeads(firstTime = true) {
      if (!firstTime) {
        this.rows = [];
        this.loading = true;
      }
      this.request++;
      let request = this.request;
      this.$store.state.server.request('leads/get', this.filters, (data) => {
        if (request === this.request) {
          this.stats = data.stats;
          this.rows = data.response;
          this.$store.state.user.leads_distribution_enabled = data.leads_distribution_enabled;
          this.activeManagers = data.activeManagers;
          this.filteredRows = data.response;
          this.sources = data.sources;
          this.leads_waiting = data.leads_waiting;
          this.loading = false;
        }
        if (this.filters.source) {
          this.selectedCount = this.filters.source ? +this.stats.bySource.find(stat => stat.source === this.filters.source).count : 0;
        } else if (this.filters.reject_reason) {
          this.selectedCount = this.filters.reject_reason ? +this.stats.byRejectReason.find(stat => stat.reject_reason === this.filters.reject_reason).count : 0;
        } else {
          this.selectedCount = this.filters.type ? +this.stats.byType.find(stat => stat.type === this.filters.type).count : 0;
        }
        if (firstTime) setTimeout(() => {
          if (this.$router.currentRoute.path === '/leads3' && +this.filters.page === 0) this.getLeads()
        }, 5000)
      }, (data) => {
        if (firstTime) setTimeout(() => {
          if (this.$router.currentRoute.path === '/leads3') this.getLeads()
        }, 5000)
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    },
    openLead(item) {
      this.$refs.lead_modal.open(item.id);
    },
    onUpdatePage(a) {
      this.filters.page = a - 1;
      setTimeout(() => {
        this.$refs.leads_table.$el.scrollIntoView({top: 0, behavior: 'smooth'});
      }, 100)
      this.getLeads(true);
    },
    onSourceSelect(stat) {
      this.filters.page = 0;
      if (stat.source) {
        this.filters.source = this.filters.source === stat.source ? '' : stat.source;
      } else if (stat.reject_reason) {
        this.filters.reject_reason = this.filters.reject_reason === stat.reject_reason ? '' : stat.reject_reason;
      } else {
        this.filters.type = this.filters.type === stat.type ? '' : stat.type;
      }
      this.getLeads();
    },
    setIsActiveManager(managerId, isActive) {
      if (!isActive && !confirm(`Убрать менеджера ${this.$root.managerName(managerId)} с линии?`)) return;
      this.set_is_active_manager_loading = true;
      this.$store.state.server.request(`leads/setIsActiveManager`, {isActive, managerId}, () => {
        this.set_is_active_manager_loading = false;
        this.$store.state.user.leads_distribution_enabled = isActive ? 2 : 1;
        this.getLeads(true);
      }, () => {
        this.set_is_active_manager_loading = false;
      });
    },
    updateLead(item) {
      this.$store.state.server.request(`leads/update/${item.id}`, item, ()=>{
        this.getLeads(true);
      })
    },
    onManagerSelect(id) {
      this.filters.manager_closed = id;
      this.getLeads();
    }
  },
  watch: {
    filters: {
      deep: true,
      handler: function (newVal) {
        this.$router.push({to: 'leads3', query: newVal}).catch(() => {
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.lead-stats {
  padding-bottom: 4px;
  border-bottom: 1px solid #eeeeee;
}

.leads_table td:not(:nth-child(4)) {
  width: 0;
  white-space: nowrap;
}
</style>
